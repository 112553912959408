import { GetServerSideProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import { client } from '~/api/client'
import {
  getCoordinateHourlyRankingsV1,
  getCoordinatesCoordinateItemsV1,
  getMembersUserNameFoldersV1,
  getOfficialRecommendedMembersV1,
  getWebTrendSearchWordsV1,
  getWebCoordinatesRecommendV1,
  getWebOfficialRecommendedCategoriesV1,
  getWebPickupTagSchedulesRecentlyV1,
  Schemas,
  searchBrands,
} from '~/api/gen'
import { Layout } from '~/components/Layout'
import { Meta } from '~/components/Meta'
import { sendGTMEventHandler } from '~/components/modules/DownloadHeader'
import { PcTopPage } from '~/features/PcTopPage/PcTopPage'
import { JP_COUNTRY_ID } from '~/features/shared/country'
import { useBaseUrl } from '~/features/shared/domain/context'
import { TOP_PAGE_JSON_LD } from '~/features/shared/topPage/lib/topPageJsonLd'
import { TOP_PAGE_HEADER_ADJUST_URL } from '~/lib/constants'
import { makeTokyoTz } from '~/lib/dateUtil'
import { Gender, sexToGender } from '~/lib/gender'
import { getUA } from '~/lib/getUA'

const GoogleAdScripts = dynamic(
  () => import('~/features/shared/ad/components/GoogleAdScripts'),
  { ssr: false }
)

type Props = {
  updatedHour: number
  gender: Gender
  recommendedMembers: Schemas.official_recommended_member_with_member[]
  brands: Schemas.brand_with_brand_aggregate[]
  trendCoordinateTags: Schemas.trend_search_word[]
  recommendedCategories: Schemas.official_recommended_category_with_category[]
  pickupTags: Schemas.pickup_tag_schedule_with_coordinates['tag'][]
  coordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[]
  recommendedCoordinates: Schemas.coordinate_small_with_member_small[]
  popularFolders: Schemas.folder_collection_with_folder_elements[]
  coordinateItems: Schemas.coordinate_item_with_coordinate_id[]
}

const POPULAR_USER_COUNT = 9
const POPULAR_BRAND_COUNT = 10
const RECOMMENDED_CATEGORIES_COUNT = 6

// ALLの場合
// [Men1,Women1,Kids1,
//  Men2,Women2,Kids2]の順でデータが含まれている
// Women1、Men2のデータを表示するために4件取得する
const PICKUP_TAG_SCHEDULES_ALL_COUNT = 4
const PICKUP_TAG_SCHEDULES_SELECT_GENDER_COUNT = 2
const PICKUP_TAG_COORDINATES_COUNT = 3

const COORDINATE_RANKING_COUNT = 3

const COORDINATE_RECOMMENDED_COUNT = 32

const WEAR_OFFICIAL_USER_NAME = 'wearofficial'
const FOLDER_FETCH_PAGE_NO = 1
const FOLDER_FETCH_COUNT_PER_PAGE = 3

const TopPage: NextPage<Props> = ({
  gender,
  updatedHour,
  recommendedMembers,
  brands,
  trendCoordinateTags,
  recommendedCategories,
  pickupTags,
  coordinateRanking,
  recommendedCoordinates,
  popularFolders,
  coordinateItems,
}) => {
  const baseUrl = useBaseUrl()

  return (
    <Layout
      breadcrumbList={[]}
      appUrl="wear2020://wear.jp"
      abstractUrl="wear.jp"
      donwloadHeaderType="temporary"
      appDownloadUrl={TOP_PAGE_HEADER_ADJUST_URL}
      onClickAppLink={sendGTMEventHandler}
      showPcAppBanner
    >
      <GoogleAdScripts pages={[{ label: 'pc:top' }]} />
      <Meta
        title="ファッションコーディネート"
        description="ZOZOが運営する日本最大級のファッションコーディネートサイト「WEAR（ウェア）」。参考になるおしゃれな服装や、今すぐほしいトレンドアイテムがたくさん！1400万枚以上の中からコーデが探せます。気に入ったアイテムはそのまま購入も！"
        url={baseUrl.href}
        appUrl="wear2020://wear.jp/"
        jsonLdList={[TOP_PAGE_JSON_LD]}
      />
      <PcTopPage
        gender={gender}
        updatedHour={updatedHour}
        recommendedMembers={recommendedMembers}
        brands={brands}
        trendCoordinateTags={trendCoordinateTags}
        recommendedCategories={recommendedCategories}
        // ALLの場合
        // [Men1,Women1,Kids1,
        //  Men2,Women2,Kids2]の順でデータが返ってくるため
        // Women1、Men2のデータを取得するために、2番目と4番目のデータを取得して渡している
        //
        // 性別指定の場合(例:Menの場合)
        // [Men1, Men2]でデータが返ってくるため、そのまま渡している
        pickupTags={
          gender === 'all'
            ? pickupTags.filter((_, i) => i === 1 || i === 3)
            : pickupTags
        }
        coordinateRanking={coordinateRanking}
        recommendedCoordinates={recommendedCoordinates}
        popularFolders={popularFolders}
        coordinateItems={coordinateItems}
      />
    </Layout>
  )
}

// eslint-disable-next-line complexity
export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}) => {
  const isSP = getUA(req.headers['user-agent'] ?? '').isSP

  if (isSP) {
    return {
      redirect: {
        statusCode: 302,
        destination: '/sp/',
      },
    }
  }

  const updatedHour = makeTokyoTz(new Date()).getHours()
  const genderIdNum = Number(query.sex_id)
  const genderId =
    genderIdNum === Gender.men ||
    genderIdNum === Gender.women ||
    genderIdNum === Gender.kids
      ? genderIdNum
      : undefined

  const [
    recommendedMembersResponse,
    brandsResponse,
    trendCoordinateTagsResponse,
    recommendedCategoriesResponse,
    pickupTagResponse,
    coordinateRankingResponse,
    recommendedCoordinateResponse,
    foldersResponse,
  ] = await Promise.allSettled([
    getOfficialRecommendedMembersV1(client, {
      parameter: {
        sex_id: genderId,
        count: POPULAR_USER_COUNT,
        country_id: JP_COUNTRY_ID,
      },
    }),
    searchBrands(client, {
      parameter: {
        count: POPULAR_BRAND_COUNT,
      },
    }),
    getWebTrendSearchWordsV1(client, {
      parameter: {
        type: 'coordinate',
        count: 8,
      },
    }),
    getWebOfficialRecommendedCategoriesV1(client, {
      parameter: {
        sex_id: genderId,
        count: RECOMMENDED_CATEGORIES_COUNT,
      },
    }),
    getWebPickupTagSchedulesRecentlyV1(client, {
      parameter: {
        sex_id: genderId,
        pickup_tag_schedules_count: genderId
          ? PICKUP_TAG_SCHEDULES_SELECT_GENDER_COUNT
          : PICKUP_TAG_SCHEDULES_ALL_COUNT,
        coordinates_count: PICKUP_TAG_COORDINATES_COUNT,
      },
    }),
    getCoordinateHourlyRankingsV1(client, {
      parameter: {
        count: COORDINATE_RANKING_COUNT,
        ranking_type: genderId ?? 0,
        country_id: JP_COUNTRY_ID,
      },
    }),
    getWebCoordinatesRecommendV1(client, {
      parameter: {
        count: COORDINATE_RECOMMENDED_COUNT,
        sex_id: genderId,
      },
    }),
    getMembersUserNameFoldersV1(client, {
      parameter: {
        sex_id: genderId,
        user_name: WEAR_OFFICIAL_USER_NAME,
        pageno: FOLDER_FETCH_PAGE_NO,
        pagesize: FOLDER_FETCH_COUNT_PER_PAGE,
      },
    }),
  ])

  const recommendedMembers: Schemas.official_recommended_member_with_member[] =
    recommendedMembersResponse.status === 'fulfilled' &&
    recommendedMembersResponse.value.type === 'success'
      ? recommendedMembersResponse.value.data.official_recommended_members
      : []

  const brands: Schemas.brand_with_brand_aggregate[] =
    brandsResponse.status === 'fulfilled' &&
    brandsResponse.value.type === 'success'
      ? brandsResponse.value.data.brands.flatMap((brand) =>
          brand.brand !== undefined ? [brand.brand] : []
        )
      : []

  const trendCoordinateTags: Schemas.trend_search_word[] =
    trendCoordinateTagsResponse.status === 'fulfilled' &&
    trendCoordinateTagsResponse.value.type === 'success'
      ? trendCoordinateTagsResponse.value.data.trend_search_words
      : []

  const recommendedCategories: Schemas.official_recommended_category_with_category[] =
    recommendedCategoriesResponse.status === 'fulfilled' &&
    recommendedCategoriesResponse.value.type === 'success'
      ? recommendedCategoriesResponse.value.data.official_recommended_categories
      : []

  if (pickupTagResponse.status === 'rejected') {
    throw new Error(`Failed to fetch pickupTag: ${pickupTagResponse.reason}`)
  }
  if (pickupTagResponse.value.type === 'error')
    throw new Error(
      `Failed to fetch pickupTag: ${pickupTagResponse.value.raw.statusText}`
    )

  const pickupTags = pickupTagResponse.value.data.pickup_tag_schedules.map(
    (item) => item.tag
  )

  const coordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[] =
    coordinateRankingResponse.status === 'fulfilled' &&
    coordinateRankingResponse.value.type === 'success'
      ? coordinateRankingResponse.value.data.coordinate_hourly_rankings
      : []

  const recommendedCoordinates: Schemas.coordinate_small_with_member_small[] =
    recommendedCoordinateResponse.status === 'fulfilled' &&
    recommendedCoordinateResponse.value.type === 'success'
      ? recommendedCoordinateResponse.value.data.coordinates
      : []

  const popularFolders: Schemas.folder_collection_with_folder_elements[] =
    foldersResponse.status === 'fulfilled' &&
    foldersResponse.value.type === 'success'
      ? foldersResponse.value.data.folders
      : []

  const coordinateIds = [
    ...pickupTags.flatMap(({ coordinates }) => coordinates.map(({ id }) => id)),
    ...coordinateRanking.map((ranking) => ranking.coordinate.id),
    ...recommendedCoordinates.map((coordinate) => coordinate.id),
  ]

  const coordinateItems: Schemas.coordinate_item_with_coordinate_id[] = []
  try {
    const coordinateItemsResponse = await getCoordinatesCoordinateItemsV1(
      client,
      {
        parameter: {
          coordinate_ids: coordinateIds.join(','),
        },
      }
    )

    if (coordinateItemsResponse.type === 'success') {
      coordinateItems.push(...coordinateItemsResponse.data.coordinate_items)
    }
  } catch {
    // noop
  }

  return {
    props: {
      updatedHour,
      gender: sexToGender(Number(query.sex_id)),
      recommendedMembers: recommendedMembers,
      brands,
      trendCoordinateTags,
      recommendedCategories,
      pickupTags,
      coordinateRanking,
      recommendedCoordinates,
      popularFolders,
      coordinateItems,
    },
  }
}
export default TopPage
